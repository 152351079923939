<template>
  <v-app>
    <v-app-bar dark app color="red darken-2">
      <v-toolbar-title class="headline text-uppercase">
        <span>Yodaforex Logs</span>
      </v-toolbar-title>
      <v-spacer/>

      <v-btn v-if="$router.currentRoute.path !== '/'" text @click="$router.push('/')">Logs</v-btn>
      <v-btn v-if="$router.currentRoute.path !== '/errors'" text @click="$router.push('/errors')">Erreurs</v-btn>
      <v-btn v-if="$router.currentRoute.path !== '/dashboard/counters'" text @click="$router.push({name: 'Dashboard', params: {mode: 'counters'}})" color="blue lighten-3">Dashboard</v-btn>
      <v-btn v-if="$router.currentRoute.path !== '/dashboard/graphs'" text @click="$router.push({name: 'Dashboard', params: {mode: 'graphs'}})" color="lime lighten-3">Graphs</v-btn>

      <y-auth-button v-model="isAuth" error-color="red accent-1" success-color="light-green lighten-1" verifying-color="warning lighten-1"/>
    </v-app-bar>

    <v-main>
      <router-view v-if="isAuth"/>
      <v-container v-else>
        <div class="d-flex justify-center">
          <v-progress-linear indeterminate/>
        </div>
      </v-container>
    </v-main>

  </v-app>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      isAuth: false
    }
  }
};
</script>

