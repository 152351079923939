var merge = require('webpack-merge')
var prodEnv = require('./prod.env')

module.exports = merge(prodEnv, {
  env: 'development',
  authConfig: {
    devMode: true
  },
  apiUrl: 'http://localhost:8080/'
})
