<template>
  <v-container fluid>
    <v-snackbar color="info" top v-model="copySuccess" :timeout="3000">Lien copié dans le presse-papier</v-snackbar>
    <v-snackbar :color="autoRefresh ? 'info' : 'secondary'" top v-model="autoRefreshStatusChanged" :timeout="3000" text>
      L'auto-refresh des logs est {{ autoRefresh ? 'activé' : 'désactivé' }}
    </v-snackbar>
    <v-dialog max-width="800" v-model="logDetailDialog">
      <log-detail @close="logDetailDialog=false" :log="selectedLog" @openInGraylog="showLogInGraylog(selectedLog)"/>
    </v-dialog>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Filtres</v-card-title>
          <v-container>
            <v-form @submit.prevent="fetchForCustomDisplay">
              <v-row>
                <v-col cols="12" sm="6">
                  <div class="d-flex">
                    <v-select dense outlined :items="timeRangeOptions" v-model="timeRange"
                              label="Rechercher sur..." hide-details placeholder="Sélectionner une durée"
                              prepend-inner-icon="mdi-clock"/>
                    <v-tooltip bottom>
                      <template v-slot:activator="{on}">
                        <v-btn class="ml-3" v-on="on" icon :color="autoRefresh ? 'primary':'secondary'" elevation="2"
                               :outlined="autoRefresh"
                               @click="autoRefresh=!autoRefresh">
                          <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                      </template>
                      Auto-refresh
                    </v-tooltip>
                  </div>
                </v-col>
                <v-col>
                  <v-dialog ref="dialog" v-model="dateDialog" :return-value.sync="date" persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field hide-details dense outlined v-model="date" label="ou sur une plage de date"
                                    prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"/>
                    </template>
                    <v-date-picker range v-model="date" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dateDialog = false">Annuler</v-btn>
                      <v-btn text color="primary" @click="$refs.dialog.save(date)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <y-branch-select outlined="" v-model="branch"/>
                </v-col>
                <v-col>
                  <y-company-select outlined="" v-model="company"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-autocomplete hide-details outlined :items="appsOptions" chips deletable-chips
                                  v-model="selectedApps"
                                  multiple label="Uniquement les applications suivantes"/>
                </v-col>
              </v-row>
              <v-row v-if="isYodaforexAppSelected">
                <v-col>
                  <div class="d-flex">
                    <v-spacer/>
                    <a href="#" @click.prevent="selectedYodaforexModules=[]" class="caption">Tout désélectionner</a>
                    <a href="#" @click.prevent="selectedDefaultYodaforexModules" class="ml-2 caption text--primary">Sélection par défaut</a>
                  </div>
                  <v-autocomplete persistent-hint outlined :items="yodaforexModulesOptions" chips small-chips deletable-chips v-model="selectedYodaforexModules" multiple label="Dans Yodaforex, uniquement les modules suivants" hint="Si aucun module n'est spécifié, tous les modules seront affichés"/>
                </v-col>
              </v-row>
              <v-row v-if="isYodaforexAppSelected">
                <v-col>
                  <v-combobox persistent-hint outlined v-model="selectedYodaforexVersions" chips deletable-chips  multiple label="Dans Yodaforex, uniquement les versions suivantes"  hint="Si aucune version n'est spécifiée, toutes les versions seront affichées. Utiliser l'étoile * comme joker, par exemple : 22.8.*"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col align-self="center" cols="12" md="2">
                  <div class="caption">Afficher les niveaux de logs suivants :</div>
                </v-col>
                <v-col>
                  <v-checkbox label="Emergency" v-model="level.emergency" color="error darken-4" dense hide-details
                              :false-value="null" :true-value="1"/>
                </v-col>
                <v-col>
                  <v-checkbox label="Critical" v-model="level.critical" color="error darken-2" dense hide-details
                              :false-value="null" :true-value="2"/>
                </v-col>
                <v-col>
                  <v-checkbox label="Error" v-model="level.error" color="error" dense hide-details :false-value="null"
                              :true-value="3"/>
                </v-col>
                <v-col>
                  <v-checkbox label="Warning" v-model="level.warning" color="warning" dense hide-details
                              :false-value="null" :true-value="4"/>
                </v-col>
                <v-col>
                  <v-checkbox label="Actions" v-model="level.unknown" color="amber" dense hide-details
                              :false-value="null" :true-value="5"/>
                </v-col>
                <v-col>
                  <v-checkbox label="Info" v-model="level.info" color="blue" dense hide-details :false-value="null"
                              :true-value="6"/>
                </v-col>
                <v-col>
                  <v-checkbox label="Debug" v-model="level.debug" color="green" dense hide-details :false-value="null"
                              :true-value="7"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field outlined v-model="messageSearch" label="Contenant le texte"/>
                </v-col>
                <v-col cols="auto" sm="auto">
                  <v-btn fab :color="autoRefresh ? 'primary darken-2' : 'primary'" @click="fetchForCustomDisplay">
                    <v-icon>{{ autoRefresh ? 'mdi-refresh' : 'search' }}</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="auto" sm="1">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn fab small color="primary" class="mt-2" v-bind="attrs" v-on="on">
                        <v-icon>share</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="openInGraylog">
                        <v-list-item-title>Ouvrir dans Graylog dans une nouvelle fenêtre</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="fetchForGraylog">
                        <v-list-item-title>Afficher via Graylog</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="copyLink">
                        <v-list-item-title>Partager la recherche</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="result || loading">
      <v-snackbar v-model="error" color="error" :timeout="3000">Une erreur est survenue</v-snackbar>
      <v-col>
        <v-card :loading="loading && !autoRefresh">
          <log-list v-if="result" @select="logHasBeenSelected" :logs="result"/>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="logUrl">
      <v-col>
        <v-card>
          <iframe :src="logUrl"
                  onload='javascript:(function(o){o.style.height=o.contentWindow.document.body.scrollHeight+"px";}(this));'
                  style="height:800px;width:100%;border:none;overflow:hidden;"/>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="logUrl">
      <v-col>
        <v-card>
          <v-card-text>{{ logUrl }}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import copy from "copy-to-clipboard";
import LogList from "@/components/LogList";
import {buildGraylogUrl, buildSearchString} from "@/business/graylog-business";
import {
  applications,
  defaultYodaforexModuleToSelect,
  idsForYodaforexModulesValue,
  valuesForYodaforexModulesIds,
  yodaforexModules
} from "@/business/yodaforex-logs-registry";
import LogDetail from "@/views/LogDetail";

export default {
  name: 'Home',
  components: {LogDetail, LogList},
  data: () => ({
    logUrl: null,
    timeRangeOptions: [
      {text: 'les dernières 5 minutes', value: 300},
      {text: 'les dernières 15 minutes', value: 900},
      {text: 'les dernières 30 minutes', value: 1800},
      {text: 'les dernières 60 minutes', value: 3600},
      {text: 'les dernières 2 heures', value: 7200},
      {text: 'les dernières 3 heures', value: 10800},
      {text: 'les dernières 6 heures', value: 21600},
      {text: 'les dernières 12 heures', value: 43200},
      {text: 'les dernières 24 heures', value: 86400},
      {text: 'les derniers 3 jours', value: 259200},
      {text: 'les derniers 15 jours', value: 1296000},
      {text: 'les derniers 30 jours', value: 2592000},
      {text: 'les derniers 45 jours', value: 3888000},
    ],
    appsOptions: applications,
    yodaforexModulesOptions: yodaforexModules,
    tableHeaders: [
      {text: "Message", value: "message.full_message", sortable: false},
      {text: "Société", value: "company"},
      {text: "Agence", value: "branch"},
      {text: "Date", value: "timestamp"},
      {text: "", value: "tograylog"},
    ],
    dateDialog: false,
    level: {
      emergency: 1, // contiennent soit "null" si décoché, soit le niveau de log correspondant si coché. Pratique pour construire l'URL après.
      critical: 2,
      error: 3,
      warning: 4,
      unknown: 5,
      info: 6,
      debug: 7
    },
    logDetailDialog: false,
    selectedLog: null,

    result: null,
    currentQuery: null,
    loading: false,
    error: false,

    copySuccess: false,
    autoRefreshStatusChanged: false,

    messageSearch: "",
    selectedApps: [],
    selectedYodaforexModules: [],
    selectedYodaforexVersions: [],
    date: null,
    company: null,
    branch: null,
    timeRange: 3600,
    autoRefresh: false,
  }),
  props: ['f', 'm'],
  timers: {
    autoRefreshLoop: {repeat: true, time: 2000} // Vitesse de rafraichissement de l'auto-refresh
  },
  watch: {
    date(newVal) {
      if (newVal) {
        this.timeRange = null;
        this.autoRefresh = false;
      }
    },
    timeRange(newVal) {
      if (newVal) {
        this.date = null;
      }
    },
    company(newVal) {
      if (newVal) {
        this.branch = null;
      }
    },
    branch(newVal) {
      if (newVal) {
        this.company = null;
      }
    },
    autoRefresh(newVal) {
      this.autoRefreshStatusChanged = true;
      if (newVal) this.$timer.start('autoRefreshLoop');
      else this.$timer.stop('autoRefreshLoop');
    }
  },
  mounted() {
    this.loadParamsFromURL()
  },
  computed: {
    isYodaforexAppSelected() {
      return this.selectedApps ? this.selectedApps.indexOf("yodaforex") >= 0 : false;
    }
  },
  methods: {
    autoRefreshLoop() {
      if (this.$route.query['m'] === 'c') { // Si on est bien sur l'affichage custom
        this.fetchForCustomDisplay()
      }
    },
    openInGraylog() {
      window.open(buildGraylogUrl(this.$config.logSearchBaseUrl, this.buildSearchOptions(), this.$moment).toString(), "_blank").focus();
    },
    showLogInGraylog(log) {
      window.open(this.$config.logLinkBaseUrl + log.index + "/" + log.message['_id'], "_blank").focus();
    },
    copyLink() {
      this.copySuccess = copy(window.location);
    },
    fetchForGraylog() {
      this.result = null;
      this.saveState('g');
      this.logUrl = buildGraylogUrl(this.$config.logSearchBaseUrl, this.buildSearchOptions(), this.$moment).toString();
    },
    fetchForCustomDisplay() {
      this.logUrl = null;
      this.loading = true;
      this.error = false;
      this.saveState('c');
      let query = buildSearchString(this.buildSearchOptions());
      let payload = {query: query};
      if (this.timeRange) {
        payload.relative = this.timeRange;
      } else {
        if (Array.isArray(this.date)) {
          payload.from = this.$moment.utc(this.date[0]).toISOString();
          payload.to = this.$moment.utc(this.date[1]).add(1, 'days').toISOString();
        } else {
          payload.from = this.$moment.utc(this.date).toISOString();
          payload.to = this.$moment.utc(this.date).add(1, 'days').toISOString();
        }
      }
      this.$http.post(this.$config.apiUrl + "v1/search", payload)
          .then(r => {
            this.currentQuery = query;
            this.result = r.data.messages;
          }).catch(() => this.error = true).finally(() => this.loading = false);
    },
    loadParamsFromURL() {
      let selfUrl = new URL(window.location);
      let params = selfUrl.searchParams;
      if (params.get("q")) this.messageSearch = params.get("q")
      if (params.get("c")) this.company = params.get("c")
      if (params.get("b")) this.branch = params.get("b")
      if (params.get("a")) this.selectedApps = params.get("a").split(',')
      if (params.get("t")) this.timeRange = Number.parseInt(params.get("t"));
      if (params.get("d1")) {
        this.timeRange = null;
        this.date = [params.get("d1")]
        if (params.get("d2")) this.date.push(params.get("d2"));
      }
      if (params.get("l")) {
        let levels = params.get("l").split(',');
        for (const levelKey in this.level) {
          if (levels.indexOf(this.level[levelKey]) < 0) { // On passe à NULL les niveaux de logs qui ne sont pas dans la liste trouvée en param
            this.level[levelKey] = null;
          }
        }
      }
      if (params.get('ym')) {
        this.selectedYodaforexModules = valuesForYodaforexModulesIds(params.get('ym').split(','));
      }
      if (params.get('yv')) {
        this.selectedYodaforexVersions = params.get('yv').split(',');
      }
      if (this.$route.query['m'] === 'g') {
        this.fetchForGraylog();
      } else this.fetchForCustomDisplay()
    },
    saveState(mode) {
      let queryParams = {};
      if (this.messageSearch) queryParams.q = this.messageSearch;
      if (this.company) queryParams.c = ((typeof this.company === 'string') ? this.company : this.company.uuid);
      if (this.branch) queryParams.b = ((typeof this.branch === 'string') ? this.branch : this.branch.uuid);
      if (this.selectedApps && this.selectedApps.length > 0) queryParams.a = this.selectedApps.join();
      if (this.date) {
        queryParams.d1 = this.date[0];
        if (this.date.length > 1) queryParams.d2 = this.date[1];
      }
      if (this.timeRange) queryParams.t = this.timeRange;
      let levels = Object.values(this.level).filter(a => !!a);
      if (levels.length < 7) queryParams.l = levels.join() // 7 => nombre de niveaux de logs différents
      queryParams.m =  mode;
      if (this.selectedApps.indexOf('yodaforex') >= 0) {
        if (Array.isArray(this.selectedYodaforexModules) && this.selectedYodaforexModules.length > 0) {
          queryParams.ym = idsForYodaforexModulesValue(this.selectedYodaforexModules).join();
        }
        if (Array.isArray(this.selectedYodaforexVersions) && this.selectedYodaforexVersions.length > 0) {
          queryParams.yv = this.selectedYodaforexVersions.join();
        }
      }
      this.$router.push({path: "/", query: queryParams});
    },
    buildSearchOptions() {
      let opts = {
        companyUuid: this.company ? ((typeof this.company === 'string') ? this.company : this.company.uuid) : null,
        branchUuid: this.branch ? ((typeof this.branch === 'string') ? this.branch : this.branch.uuid) : null,
        selectedApps: Array.from(this.selectedApps),
        messageSearch: this.messageSearch,
        level: this.level,
        dateRange: this.dateRange,
        date: this.date
      };

      let indexOfYfx = opts.selectedApps.indexOf('yodaforex');
      if (indexOfYfx >= 0 && ((this.selectedYodaforexModules && this.selectedYodaforexModules.length > 0) || (this.selectedYodaforexVersions && this.selectedYodaforexVersions.length > 0))) {
        let yfx = {identifier: 'yodaforex'};
        if (this.selectedYodaforexModules && this.selectedYodaforexModules.length > 0) {
          let allModules = [];
          this.selectedYodaforexModules.forEach((ma) => (ma.forEach(m => allModules.push(m))));
          yfx['yfx_module_name'] = allModules
        }
        if (this.selectedYodaforexVersions && this.selectedYodaforexVersions.length > 0) {
          yfx['version_yodaforex'] = this.selectedYodaforexVersions;
        }
        opts.selectedApps.splice(indexOfYfx, 1);
        opts.selectedApps.push(yfx)
      }

      return opts;
    },
    logHasBeenSelected(log) {
      this.selectedLog = log;
      this.logDetailDialog = true;
    },
    selectedDefaultYodaforexModules() {
      this.selectedYodaforexModules = defaultYodaforexModuleToSelect();
    }
  }
}
</script>
