<template>
  <v-container>
    <v-dialog max-width="800" v-model="logDetailDialog">
      <log-detail @close="logDetailDialog=false" :log="selectedLog" @openInGraylog="showLogInGraylog(selectedLog)"/>
    </v-dialog>
    <v-card :loading="loading">
      <v-card-title class="d-flex align-center">
        <span>Erreurs des dernières 48h</span>
        <v-spacer/>
        <v-switch v-model="includeYodaforexApp" label="Inclure Yodaforex"/>
      </v-card-title>
      <log-list v-if="result" @select="logHasBeenSelected" :logs="result"/>
      <v-progress-linear indeterminate v-else/>
    </v-card>
  </v-container>
</template>

<script>

import LogList from "@/components/LogList";
import LogDetail from "@/views/LogDetail";

const ERROR_FILTER =                   "level: 3 AND NOT (app_name: yodaforex AND (message: \"404 Requete : POST - https://backup-api.yodadev.tools/backup/0\" OR version_yodaforex: 23.0.0 OR yfx_module_name: \"s2s3_file_manager\" OR (message_4D: \"Already locked\" AND yfx_module_name: \"bankLine_create\"))) AND NOT company_uuid: \"0DA258250B13460582B500F9E0595B6B\" AND NOT app_name: \"cloud-services-usage-api\" AND NOT branch_uuid: \"38E271CDA33444539679AD639A64D9EB\" AND NOT message: \"Error while trying to record usage to service-usage-api\" AND NOT (message: \"file not found\" AND source: \"display-app-online\")";
const ERROR_FILTER_WITHOUT_YODAFOREX = "level: 3 AND NOT (app_name: yodaforex AND (message: \"404 Requete : POST - https://backup-api.yodadev.tools/backup/0\" OR version_yodaforex: 23.0.0 OR yfx_module_name: \"s2s3_file_manager\" OR (message_4D: \"Already locked\" AND yfx_module_name: \"bankLine_create\"))) AND NOT company_uuid: \"0DA258250B13460582B500F9E0595B6B\" AND NOT app_name: \"cloud-services-usage-api\" AND NOT branch_uuid: \"38E271CDA33444539679AD639A64D9EB\" AND NOT message: \"Error while trying to record usage to service-usage-api\" AND NOT (message: \"file not found\" AND source: \"display-app-online\") AND NOT app_name: yodaforex";

export default {
  name: "ErrorView",
  components: {LogDetail, LogList},
  data: () => ({
    error: false,
    loading: false,
    result: null,
    selectedLog: null,
    logDetailDialog: false,
    includeYodaforexApp: false
  }),
  mounted() {
    this.fetch();
  },
  watch: {
    includeYodaforexApp() {
      this.fetch();
    }
  },
  methods: {
    fetch() {
      this.loading = true;
      this.$http.post(this.$config.apiUrl + "v1/search", {query: this.includeYodaforexApp ? ERROR_FILTER : ERROR_FILTER_WITHOUT_YODAFOREX, relative: 86400*2})
          .then(r => this.result = r.data.messages)
          .catch(() => this.error = true)
          .finally(() => this.loading = false);
    },
    showLogInGraylog(log) {
      window.open(this.$config.logLinkBaseUrl + log.index + "/" + log.message['_id'], "_blank").focus();
    },
    logHasBeenSelected(l) {
      this.logDetailDialog = true;
      this.selectedLog = l;
    }
  }
}
</script>

<style scoped>

</style>
