<template>
  <iframe :src="dashboardUrl"
          onload='javascript:(function(o){o.style.height=o.contentWindow.document.body.scrollHeight+"px";}(this));'
          style="height:100%;width:100%;border:none;overflow:hidden;"/>
</template>

<script>

const URL_COUNTER = "https://gra2.logs.ovh.com/dashboards/tv/5d1c59238671c90001090ada?interval=1&refresh=10&tabs=0"
const URL_GRAPHS = "https://gra2.logs.ovh.com/dashboards/tv/5d1c59238671c90001090ada?interval=1&refresh=600&tabs=1"

export default {
  name: "Dashboard",
  props: ['mode'],
  computed: {
    dashboardUrl() {
      switch (this.mode) {
        case 'counters': return URL_COUNTER;
        case 'graphs': return URL_GRAPHS;
        default: this.$router.push('/dashboard/counters')
      }
    }
  }

}
</script>

<style scoped>

</style>
