export function buildSearchString({branchUuid, companyUuid, level, messageSearch, selectedApps}) {
	let r = "";
	// Société ou agence
	if (companyUuid) {
		r += `company_uuid: "${companyUuid}"`
	} else if (branchUuid) {
		r += `branch_uuid: "${branchUuid}"`
	}
	if (selectedApps && selectedApps.length) {
		if (r.length) r += " AND ";
		r += "(";
		let appCount = selectedApps.length;
		for (let i = 0; i < appCount; i++) {
			let app = selectedApps[i];
			if (typeof app === "string") {
				r += `app_name: "${app}"`
			} else if (typeof app === "object" && app.identifier) {
				r += `(app_name: "${app.identifier}"`
				let fieldsToFilter = Object.keys(app).filter(f => f !== "identifier");
				if (fieldsToFilter.length > 0) {
					r+=" AND ";
				}
				for (let j = 0; j < fieldsToFilter.length; j++) {
					if (Array.isArray(app[fieldsToFilter[j]])) {
						r += `(${fieldsToFilter[j]}: (${app[fieldsToFilter[j]].join(' OR ')}))`;
					}
					if (j < fieldsToFilter.length - 1) {
						r += " AND ";
					}
				}
				r += `)`
			}
			if (i < appCount - 1) {
				r += " OR ";
			}
		}
		r += ")";
	}
	if (messageSearch) {
		if (r.length) r += " AND ";
		r += `message: "*${messageSearch}*"`
	}
	if (level) {
		// Parce qu'on ne sait pas encore s'il y a des éléments cochés dans la liste, on construit une chaine à part, qu'on rajoutera uniquement s'il y avait quelque chose
		let levelQuery = "";
		if (r.length) levelQuery += " AND ";
		levelQuery += '(';
		let atLeastOne = false;
		let checkCount = 0;
		for (const k in level) {
			if (level[k]) {
				if (atLeastOne) {
					levelQuery += " OR ";
				}
				atLeastOne = true;
				checkCount++;
				levelQuery += `level: ${level[k]}` // chaque level contient son niveau de log
			}
		}
		levelQuery += ')';
		if (atLeastOne && checkCount < 7) { // S'il y en a au moins 1, mais pas tous
			r += levelQuery;
		}
	}
	return r;
}

export function addTimeConstraintsToUrl(url, {timeRange, date}, momentInstance) {
	if (timeRange) {
		url.searchParams.append('relative', timeRange);
		url.searchParams.append('rangetype', 'relative');
	} else if (date) {
		if (Array.isArray(date)) {
			// Plage de date
			url.searchParams.append('rangetype', "absolute");
			url.searchParams.append('from', momentInstance.utc(date[0]).toISOString());
			url.searchParams.append('to', momentInstance.utc(date[1]).add(1, 'days').toISOString());
		} else {
			// Date unique
			url.searchParams.append('rangetype', "absolute");
			url.searchParams.append('from', momentInstance.utc(date).toISOString());
			url.searchParams.append('to', momentInstance.utc(date).add(1, 'days').toISOString());
		}
	}
}

export function buildGraylogUrl(baseUrl, {branchUuid, companyUuid, level, messageSearch, selectedApps, timeRange, date}, momentInstance) {
	let url = new URL(baseUrl);
	url.searchParams.append('q', buildSearchString({branchUuid, companyUuid, level, messageSearch, selectedApps}));
	addTimeConstraintsToUrl(url, {timeRange, date}, momentInstance);
	return url;
}
