import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from "@/views/Dashboard";
import ErrorView from "@/views/ErrorView";

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		props: route => ({ f: route.query.f , m: route.query.m}),
		component: Home
	},
	{
		path: '/dashboard/:mode',
		name: 'Dashboard',
		component: Dashboard,
		props: true
	},
	{
		path: '/errors',
		name: 'ErrorView',
		component: ErrorView,
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
