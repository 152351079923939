<template>
  <v-list>
    <v-list-item v-if="!logs || logs.length===0">
      <v-list-item-content>
        Aucun résultat
      </v-list-item-content>
    </v-list-item>
    <v-list-item-group>
      <v-list-item three-line v-for="(log, i) in logs" :key="i" @click="$emit('select',log)">
        <v-list-item-icon>
          <v-icon :color="log.message.level | levelToColor">{{ log.message.level | levelToIcon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle>{{ log.message.timestamp | moment('yyyy-MM-DD HH:mm:ss') }} —
            {{ log.message.app_name | appIdToLabel() }}{{ log.message.version_yodaforex ? ' ' + log.message.version_yodaforex : '' }}{{ log.message.yfx_module_name ? ' — ' + log.message.yfx_module_name : '' }}
            <span class="caption" v-if="log.message.SourceSimpleClassName && log.message.SourceMethodName && log.message.SourceLineNumber"> — {{ log.message.SourceSimpleClassName }}.{{ log.message.SourceMethodName }}:{{ log.message.SourceLineNumber }}</span>
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="log.message.company_uuid || log.message.branch_uuid">
            <y-company-label v-if="log.message.company_uuid" :value="log.message.company_uuid"/>
            —
            <y-branch-label v-if="log.message.branch_uuid" :value="log.message.branch_uuid"/>
            {{ log.message.user_name ? ' — ' + log.message.user_name : '' }}
            <span v-if="log.message.hasOwnProperty('from_server')">&nbsp;—&nbsp;<v-icon x-small>mdi-{{ log.message.from_server === 'true' ? 'desktop-tower' : 'desktop-mac'}}</v-icon></span>
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else-if="log.message.caller_ip">
            <y-branch-label-from-ip :value="log.message.caller_ip"/>
          </v-list-item-subtitle>
          <v-list-item-title class="text--primary">{{ log.message.message }}</v-list-item-title>
          <v-list-item-subtitle v-if="log.message.message_4D" class="text--secondary">Message d'erreur 4D : {{ log.message.message_4D }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>


import {getLabelForAppId} from "@/business/yodaforex-logs-registry";

export default {
  name: "LogList",
  props: {
    logs: {
      type: Array,
      default: []
    }
  },
  filters: {
    levelToColor(level) {
      switch (level) {
        case 1:
          return 'error darken-4'
        case 2:
          return 'error darken-4'
        case 3:
          return 'error';
        case 4:
          return 'warning';
        case 5:
          return 'amber';
        case 6:
          return 'blue';
        case 7:
          return 'green';
      }
    },
    levelToIcon(level) {
      switch (level) {
        case 1:
        case 2:
          return 'error_outline';
        case 3:
          return 'error';
        case 4:
        case 5:
          return 'warning';
        case 6:
        case 7:
          return 'info';
      }
    },
    appIdToLabel(appId) {
      return getLabelForAppId(appId);
    }
  },
}
</script>

<style scoped>

</style>
