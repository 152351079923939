<template>
  <v-card>
    <v-card-title>Détail
      <v-spacer/>
      <v-btn color="red" text @click="$emit('openInGraylog')">Voir dans Graylog</v-btn>
      <v-btn icon @click="$emit('close')">
        <v-icon>close</v-icon>
      </v-btn>
    </v-card-title>
    <v-list v-if="log">
      <v-list-item two-line v-for="(val,key,i) in log.message" :key="i">
        <v-list-item-content>
          <v-list-item-title>{{ key }}</v-list-item-title>
          <v-list-item-subtitle>{{ val }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>
<script>
export default {
  name: 'log-detail',
  props: {
    log: {},
  }
}
</script>
