export function defaultYodaforexModuleToSelect() {
	return yodaforexModules.filter(m => m.text !== "Scan vers Cloud").map(m=>m.value);
}

export const applications = [
	{text: 'Yodaforex', value: 'yodaforex'},
	{text: 'Yodaforex Launcher', value: 'yodaforex-launcher'},
	{text: 'Yodaforex Web Input API', value: 'yfx-web-input-api'},
	{text: 'Yodaforex QR Code Facture API', value: 'yfx-invoice-integrity-control-api'},
	{text: 'Yodaforex Overview', value: 'cloud-overview-api'},
	{text: 'Yodaforex CodePatch API', value: 'yfx-codepatch-api'},
	{text: 'Yodaforex License API', value: 'yfx-license-api'},
	{text: 'YodaCloud Model API', value: 'wakanda-manage-api'},
	{text: 'YodaCloud Consult API', value: 'cloud-consult-api'},
	{text: 'Web-Yodaforex Connect API', value: 'web-yfx-connect-api'},
	{text: 'Web-Yodaforex Connect Registry API', value: 'web-yfx-connect-registry-api'},
	{text: 'YodaQuotes API', value: 'yfx-quotes-api'},
	{text: 'Yodaforex Cloud Auth API', value: 'cloud-auth-api'},
	{text: 'Yodaforex Computer Park API', value: 'yfx-computer-park'},
	{text: 'Yodaforex Helper API (IBAN/Mermaid)', value: 'yfx-helper-api'},
	{text: 'YodaDisplay Proxy Cache', value: 'display-app-proxy-cache'},
]

let appsLabel = {};
for (const app of applications) {
	appsLabel[app.value] = app.text;
}
export const applicationLabelForId = Object.assign({}, appsLabel);

export function getLabelForAppId(appId) {
	return applicationLabelForId[appId] ? applicationLabelForId[appId] : appId;
}

export const yodaforexModules = [
	{
		id: 'openquit',
		text: 'Ouverture et fermeture',
		value: ['"Sur démarrage serveur"', '"Sur fermeture"', '"Sur ouverture"', '"Process principal"', '"Gestionnaire client"', '"OBW_*"', '"Sur arrêt serveur"']
	},
	{id: 'user-session', text: 'Session utilisateur', value: ['session_*', 'SESSION_*', 'YFX_SESSION_*']},
	{id: 'pdt', text: 'Produits', value: ['PDT_*', 'pdt_*']},
	{id: 'bk', text: 'Banques', value: ['bankLine_*', 'bank_*']},
	{id: 'inv', text: 'Factures', value: ['INV_*', 'invoice_*']},
	{id: 'acc', text: 'Comptabilité', value: ['COMPTA_*']},
	{id: 'acc-plan', text: 'Plan comptable', value: ['accountPlan_*']},
	{id: 'reg', text: 'Livre de police', value: ['register_*']},
	{id: 'user', text: 'Utilisateurs', value: ['user_*']},
	{id: 'client', text: 'Comptes client', value: ['CUST_*']},
	{id: 's2c', text: 'Scan vers Cloud', value: ['s2c_*', 's2s3_*']},
	{id: 'web', text: 'Synchro site web', value: ['commandes_*', 'website_*']},
	{id: 'dsp', text: 'YodaDisplay', value: ['DSP_*']},
	{id: 'pref', text: 'Préférences Yodaforex', value: ['pref_*', 'PREF_*']},
	{id: 'ycd', text: 'YodaCloud', value: ['ycd_*']},
	{id: 'end-of-month', text: 'Clôture de fin de mois', value: ['FDM_*']},
	{id: 'quotes', text: 'Récupération des cours', value: ['quotes_*']},
	{id: 'license', text: 'Gestion de la licence Yodaforex', value: ['license_*']},
	{id: 'hf-updt', text: 'Mise à jour par hotfix', value: ['"Gestion des hotfix"']},
	{id: 'zd', text: 'ZenDesk', value: ['ZDK_*']},
	{id: 'dj', text: 'Dow Jones', value: ['DJ_*', 'dowjones_*']},
	{id: 'hlp-btn', text: 'Utilisation des boutons d’aide', value: ['bouton\-aide*']},
	{id: '4d-err', text: 'Erreurs interpréteur 4D', value: ['log_*']},
	{id: '4d-cache', text: 'Mémoire cache 4D', value: ['yfx_cacheMemoryOptimizer', 'YFX_MEMORY_OPTIMIZER']},
	{id: 'local-bkp', text: 'Sauvegarde locale', value: ['bkp_*', '"Sur arrêt sauvegarde"', '"Sur démarrage sauvegarde"']},
	{id: 'ext-srv', text: 'Appels externes', value: ['PARTNER_*', 'api_*', 'IT_*']},
	{id: 'misc', text: 'Méthodes utilitaires', value: ['ZGEN_*', 'tbx_*']},
	{id: 'mail', text: 'Envoi de mail', value: ['MJ_*', 'MAIN_MAIL']},
	{id: 'disk-usg', text: 'Alertes d’espace disque', value: ['"Verification de l\'espace disque disponible"']},
	{id: 'remote-bkp', text: 'Sauvegarde externalisée', value: ['backupS3_*']},
	{id: 'data-intgrty', text: 'Vérification de l’intégrité du fichier de données', value: ['tbx_datafileCheck']},
	{id: 'yfx-lnchr', text: 'Gestion du Launcher', value: ['launcher_*']},
]

export function idsForYodaforexModulesValue(values) {
	return yodaforexModules.filter(m => values.indexOf(m.value) >= 0).map(m => m.id);
}

export function valuesForYodaforexModulesIds(ids) {
	return yodaforexModules.filter(m => ids.indexOf(m.id) >= 0).map(m => m.value);
}
